.bg-grid{
    background-image: url("../../utils/images/BG.png");
    width: 100%;
    height: 100%;
}

.autoEvaluation-background {
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.09);
    height: 1490px;
    left: 50%;
    position: relative;
    transform: rotate(-1.81deg) translateX(-50%);
    width: 914px;
    margin-bottom: 200px;
    margin-top: 100px;
}

.autoEvaluation-container {
    transform: rotate(1.81deg);
    color: #193B54;
}

.close-modal {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 55px;
    top: 55px;
}

.close-modal:hover {
    cursor: pointer;
}

.autoEvaluation-title {
    font-family: "Crimson Text"; 
    font-weight: 400;
    font-style: italic;
    font-size: 35px;
    line-height: 44px;
    text-align: center;
}

.under-title-line {
    position: relative;
    /* width: 200px; */
    height: 13.38px;
    left: 50%;
    transform: rotate(-176.92deg);
    transform: translateX(-50%);
    top: -10px;
}

.radio-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin: 0;
    transform: translateX(0%);
}

.radio-container .radio-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.radio-container-mobile .radio-item-mobile {
    display: flex;
    flex-direction: column;
    width: 20%;
    text-align: center;
    align-items: center;
}

.radio-container input:hover {
    cursor: pointer;
}

.radio-container-mobile {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin: 0;
}

.radio-item span {
    position: relative;
    font-family: "Roboto Condensed";
    font-size: 18px;
    line-height: 21px;
    padding-top: 10px;
    color: #b1b1b1;
}

.radio-title {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 60px;
}

.radio-line {
    width: 720px;
    height: 2px;
    border: 1px solid #CCCCCC;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
}

.radio-line-mobile {
    width: 90vw;
    height: 2px;
    border: 1px solid #CCCCCC;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 50px; 
}

.radio-container .radio-item input:checked {
    /* width: 22px;
    height: 22px;
    left: 601px;
    top: 305px;

    background: #193B54;
    border: 1px solid #193B54;
    box-sizing: border-box; */
}

.radio-container .radio-item input:checked ~ span {
    color: #193B54;
}

.autoEvaluation-next-button {
    position: relative;
    width: 130px;

    left: 65%;
    top: 4%;
}

.autoEvaluation-next-button-mobile {
    position: relative;
    width: 130px;

    left: 50%;
    top: 20px;
}

.autoEvaluation-next-button:hover {
    cursor: pointer;
}

.autoEvaluation-next-button span{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translateY(-80%);
    /* transform: rotate(-5.45deg); */

    font-family: Crimson Text;
    font-style: italic;
    font-weight: bold;
    font-size: 29px;
    line-height: 36px;

    color: white;
}

.autoEvaluation-next-button-mobile span{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translateY(-80%);
    /* transform: rotate(-5.45deg); */

    font-family: Crimson Text;
    font-style: italic;
    font-weight: bold;
    font-size: 29px;
    line-height: 36px;

    color: white;
}