.poem-container {
  width: 100vw;
  height: 100vh;
  /* background-image: url(../../utils/images/grid.png); */
  z-index: -1;
  overflow: auto;
}

.poem-container-mobile {
  width: 100%;
  height: 100vh;
  border-radius: 25px;
  background-color: #fff;
}

.poem-container-mobile .poem-background {
  box-shadow: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.poem-container-mobile::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.poem-container-mobile {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.poem-background {
  background: #fff;
  width: 100%;
  /* height: 125%; */
  height: calc(100% + 150px);
  position: absolute;
  top: -40px;
  left: 0;
  z-index: -1;
  /* transform: rotate(-1.81deg); */
  -webkit-box-shadow: 0px 0px 30px -2px rgba(153,153,153,.5);
  -moz-box-shadow: 0px 0px 30px -2px rgba(153,153,153,.5);
  box-shadow: 0px 0px 30px -2px rgba(153,153,153,.5);
}

.poem-content {
  width: 1100px;
  margin: auto;
  padding: 150px;
  padding-top: 50px;
  padding-bottom: 175px;
  position: relative;
  z-index: 10;
  font-family: "Crimson Italic";
  margin-bottom: 300px;
}

.poem-content-mobile {
  /* overflow: hidden; */
  padding: 0px 20px;
  width: 100%;
  margin-bottom: 40px;
  top: 60px;
  height: 90vh;
  border-radius: 20px;
}

.poem-content.first-page {
  padding-bottom: 125px;
}

.poem-container.last-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.last-page .poem-content{
  width: 500px;
  height: 500px;
  position: relative;
  z-index: 10;
  font-family: "Crimson Italic";
  padding: 10px 50px;
  margin: auto;
  z-index: 10;
}

.last-page .poem-content .title , .last-page .poem-content .title p{
  font-size: 50px;
  font-style: italic;
  font-family: "Crimson Italic";
  font-weight: bold;
  margin-bottom: 15px;
  z-index: 10;
}

.last-page .poem-content .subtitle, .last-page .poem-content .subtitle p {
  margin-bottom: 15px;
  z-index: 10;
}

.last-page .poem-content .poem-paragraph, .last-page .poem-content .poem-paragraph p {
  font-size: 0.9rem;
  z-index: 10;
}

.poem-container.last-page .poem-background {
  height: 100%;
  width: 100%;
}

.poem-content span {
  background: #fdfda1;
}

.poem-content .close-button {
  position: absolute;
  top: 60px;
  left: 50px;
  cursor: pointer;
  z-index: 10000;
}

.poem-content .close-button-mobile {
  top: 40px;
  left: 25px;
}

.poem-title-container {
  position: relative;
}

.title, .title p {
  font-size: 40px!important;
  color: #193B54!important;
  margin-bottom: 0px;
  font-family: "Crimson Italic"!important;
  z-index: 10!important;
}

.title i, .title b  {
  font-family: "Crimson Italic";
}

.poem-title-content .title.mt-100 {
  margin-top: 100px;
}

.poem-content .subtitle, .poem-content .subtitle p {
  font-size: 20px!important;
  color: #193B54!important;
  font-family: "Roboto Condensed"!important;
  margin-bottom: 50px;
  font-style: normal!important;
  z-index: 10;
}

.disabled {
  background: #fff;
  color: #193B54;
  font-weight: bold;
  font-family: "Crimson Italic";
}

.poem-content .subtitle.fs-25 {
  font-size: 25px;
}

.poem-content .subtitle.fs-30 {
  font-size: 30px;
}

.fs-60 {
  font-size: 60px!important;
}

.fs-30 {
  font-size: 30px!important;
}

.fs-25 {
  font-size: 25px!important;
}
.poem-row {
  font-size: 20px;
}

.next-button-container {
  position: absolute;
  bottom: -250px;
  right: -100px;
}

/* .next-button-container-mobile {
  position: relative;
  left: 200px;
} */

.next-button-container.last-page {
  position: relative;
  bottom: 0;
  right: 0;
}

.next-button-container .next-button {
  position: absolute;
  left: -60%;
  top: -10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  font-style: italic;
  font-weight: 500;
  z-index: 10;
}

.next-button-container-mobile .next-button-mobile, .next-button-container-mobile .next-button {

  top: -10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  font-style: italic;
  font-weight: 500;
  z-index: 10;

  position: relative;
  right: 0px;
  justify-content: left;
  transform: scale(0.75);
  left: -30px;
}

.next-button-container-mobile  .next-button-mobile-first {
  justify-content: flex-start;
}

.next-button-container-mobile {
  display: flex;
}

.next-button-container-mobile .nate-image {
  position: relative;
  transform: scale(0.65);
  left: -5rem;
}

.next-button-container.last-page .next-button {
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  width: 215px;
  height: 60px;
  margin-top: 20px;
  transform: rotate(1deg);
}

.next-button-container.last-page .poem-triangle{
  width: 215px;
  height: 60px;
  transform: rotate(-4deg);
}

.next-button-container.last-page .bubble-text {
  transform: rotate(2deg);
  font-family: "Crimson Italic";
  font-style: italic;
  font-weight: 600;
}

.next-button-container.last-page .triangle{
  right: 15px;
}

.next-button-container.last-page .next-button:hover .second-triangle{
  right: 7px;
}

.next-button-container.last-page .next-button:hover .second-triangle {
  border-width: 30px 2px 15px 70px;
}

.next-button-container .next-button:hover {
  color: #E2252B;
}

.next-button-container .next-button:hover .poem-triangle {
  background:#fff;
  border: 3px solid #E2252B;
}

.next-button-container .next-button:hover .triangle {
  border-style: solid;
  border-width: 20px 0 15px 60px;
  border-color: transparent transparent transparent #fff;
}



.next-button-container .next-button:hover  .second-triangle {
  width: 0;
  position: absolute;
  height: 0;
  transform: rotate(40deg);
  border-style: solid;
  border-width: 25px 0 20px 70px;
  border-color: transparent transparent transparent #E2252B;
  bottom: -16px;
  right: -10px;
  z-index: -1;
}

.bubble-text {
  transform: rotate(7deg);
  letter-spacing: 0.07rem;
  font-weight: bold;
  font-family: "Crimson Italic";
}

.poem-triangle {
  position: relative;
  width: 190px;
  height: 90px;
  border-radius: 45px;
  transform: rotate(-7deg);
  background: #E2252B;
  justify-content: center;
  align-items: center;
  display: flex;
}

.poem-triangle .triangle {
  content: "";
  width: 0;
  position: absolute;
  height: 0;
  transform: rotate(45deg);
  border-style: solid;
  border-width: 20px 0 15px 60px;
  border-color: transparent transparent transparent #E2252B;
  bottom: -20px;
  right: 0;
}


.next-button-container .bubble-image {
  position: absolute;
  left: -10%;
  top: -10%;
  z-index: -1;
  width: 190px;
}

.poem-image {
  position: absolute;
  left: 40%;
  top: -25%;
}

.poem-paragraph {
  font-family: "Roboto Condensed";
  font-size: 18px;
  font-style: normal;
  color: #4D616F;
  /* display: inline-grid; */
  margin-bottom: 2rem;
  z-index: 10;
}


.poem-paragraph u , .poem-paragraph em, .poem-paragraph i ,  .poem-paragraph strong {
  display: inline;
  margin-left: 3px;
  
}

.crimson, .crimson i, .crimson em , .crimson u  {
  font-family: "Crimson Italic"!important;
}

.crimson b , .crimson strong {
  font-family: "Crimson Text"!important;
}


/* 
.poem-paragraph b, .poem-paragraph i {
  display: inline;
  margin-left: 3px;
} */


.loading-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container.medium {
  width: 100%;
  height: 100%;
}

.from-admin {
  margin-top: 30px;
  width: unset!important;
  border: none;
  outline: none;
}

.from-admin::-webkit-scrollbar {
  display: none;
}






.page-container h3 {
  font-size: 40px;
  color: #193B54;
  margin-bottom: 40px;
  font-family: "Crimson Italic";
}


.page-container h5 {
  color: #193B54;
  font-family: "Roboto Condensed";
  margin-bottom: 50px;
  font-style: normal;
  font-size: 25px;
}


.page-container p {
  font-family: "Roboto Condensed";
  font-size: 18px;
  font-style: normal;
  color: #4D616F;
  /* display: inline-grid; */
  margin-bottom: 2rem;
  min-width: 5%;
}

.page-container div.flex {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
}

.page-container div.flex p {
  margin-bottom: 0;
  font-size: 25px;
  line-height: 31px;
  font-family: "Crimson Italic";
  min-width: 7%;
}

.page-container p b, .page-container p i {
  display: inline;
}


.page-container span {
  background: #fdfda1;
}


.page-container h2 {
  font-size: 40px;
  color: #193B54;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
}

.page-container .line-input {
  border: none;
  width: 100%;
  font-size: 20px;
  height: 40px;
}

.line-input.text-area {
  resize: none;
  overflow:hidden
}

.line-input:focus {
 outline: none;
}

.line-input {
  border: none;
  width: 100%;
  font-size: 20px;
  height: 40px;
}

.line-input:focus {
  outline: none;
}

.row-container {
  position: relative;
  margin-bottom: 2rem;
}

.row-container .row-paragraph {
  height: 100%;
  position: absolute;
  top: 0%;
  background-color: #fff;
 /* margin: 0;
  align-items: center;
  display: flex;
  font-size: 22px;
  padding-right: 5px; */
}

.points-image {
  position: absolute;
    left: -170%;
    top: -15%;
    width: 300px;
}

.page-container {
  margin-top: 50px;
}

.page-container.first-page {
  margin-top: 20px;
}
