.invitation-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.accept-invitation-container {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accept-invitation-container .login-submit-button {
  width: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.accept-invitation-container .login-submit-button:hover {
  opacity: .6;
}

.invitation-modal-header .close {
  position: unset;
  width: 60px;
}

.modal-content {
  height: 100%;
  padding: 20px;
}