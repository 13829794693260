.card-container {
    position: relative;
    width: 47vw;
    min-width: 500px;
    height: 13.5vw;
    /* left: 50%;
    transform: translateX(-50%); */
    display: flex;
    color: #FFFFFF;

    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    box-shadow: 0px 12px 14px rgba(0, 0, 0, 0.04);
    border-radius: 40px;
    white-space: nowrap;
}

.card-container-mobile {
    position: relative;
    width: 85vw;
    min-width: 250px;
    height: 60%;
    min-height: 250px;
    /* left: 50%;
    transform: translateX(-50%); */
    display: flex;
    color: #FFFFFF;

    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    box-shadow: 0px 12px 14px rgba(0, 0, 0, 0.04);
    border-radius: 40px;
    white-space: nowrap;
    flex-direction: column;
    margin: 60px auto;
}

.card-container:hover {
    border: 3px solid rgba(25, 59, 84, 0.8);
}

.card-left-side {
    display: flex;
    width: 50%;
    align-self: flex-start;
    /* border: 2px solid blue; */
    height: 100%;
    justify-content: center;
    align-content: center;
}

.card-right-side {
    display: flex;
    width: 50%;
    align-self: flex-end;
    /* border: 2px solid orange; */
    height: 100%;
    justify-content: center;
    align-content: center;
}

.card-data {
    width: 100%;
    height: 100%;
    
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-data-chapter {
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
}

.card-data-chapter-title {
    font-family: Crimson Text;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 130%;
    /* or 45px */
}

.card-data-button { 
    background: #E2252B;
    border-radius: 31.5452px;
    width: 120px;
    height: 2.5vw;

    font-family: Crimson Text;
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
    border: none;
    z-index: 9999999 !important;
    position: relative;
}

.card-data-button:focus {
    outline: none;
}

.button-roulette-container {
    position: relative;
}

.card-image {
    border-radius: 40px;
    width: 13vw;
    height: 220px;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
}

.poem-content-mobile {
    height: unset!important;
    margin-bottom: 200px;
    min-height: 100vh;
}

.chapter-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chapter-image {
    position: relative;
    /* left: 35%; */
    /* top: 50%; */
    /* transform: translate(-50%, -50%); */
    /* height: 14.5vw; */
    height: 100%;
    object-fit: cover;
}

.mg-10 {
    margin: 10px 0;
}

.incomplete {
    background: #CCCCCC;
    cursor: auto !important;
}

.complete {
    background: #10cc10;
    cursor: auto !important;
}

.roulette-choosing {
    background: #AAAAAA;
    cursor: auto !important;
}

.lock-image {
    position: absolute;
    top: 10px;
    right: 0px;
    margin-top: 10px;
    margin-right: 10px;
    z-index: 100;
}

.lock-image-left {
    left: 0px;
    top: -30px;
}