.header-button {
  background: transparent!important;
  color: #222!important;
  border:none!important;
}

.header-button:hover {
  color: #e5e5e5!important;
}


.dark-color {
  color: #222!important;
}

.dropdown-toggle {
  width: 100%;
}


#root {
  overflow-x: hidden;
}



@media (max-width:768px) {
  .header-button.dropdown-mobile-button, .btn-outline-sm.dropdown-mobile-button  {
    margin-right: 0!important;
  }
}


@media (min-width: 2000px) {
  .header-container {
    height: 100px;
  }
  .header-button {
    font-size: 35px;
    height: 150px;
    display: flex!important;
    justify-content: center;
    align-items: center;
  };

  .header-toggle-button {
    height: 100%;
    font-size: 35px!important;
  }

  .header-button button {
    height: 80px;
    width: 300px;
    font-size: 25px!important;
  }

  .logout-button {
    width: 160px;
    height: 80px;
    font-size: 25px!important;
  }
  .navbar-brand img {
    width: 250px;
  }
  .navbar-expand-lg .navbar-nav {
    align-items: center;
  }
  .dropdown-menu {
    min-width: 100%!important;
    font-size: 25px!important;
  }
}

@media (min-width: 3000px) {
  .navbar-expand-lg .navbar-nav {
    align-items: center;
  }
  .header-container {
    height: 150px;
  }
  .header-button button {
    height: 100px;
    width: 400px;
    font-size: 40px!important;
  }

  .logout-button {
    width: 200px;
    height: 100px;
    font-size: 40px!important;
  }

.navbar-brand img {
    width: 400px;
  }

  .dropdown-menu {
    min-width: 100%!important;
    font-size: 35px!important;
  }
}

