.register-text-input {
    height: 80px;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    border: 1px solid #e5e5e5;
    font-size: 1.5rem;
}
.register-form {
    color: #193B54;
    font-family: "Roboto Condensed";
    width: 100vw;
    height: 100vh;
}

.register-text-input::placeholder {
    color: #999;
}

.register-input-container {
    display: flex;
    width: 70%;
    margin: 10px;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
}

.register-select-container {
    width: 400px;
    position: relative;
}

.register-select {
    height: 80px;
}

.error-container{
    position: relative;
    width: 70%;
    margin-top: 15px;
    font-size: 20px;
    top: -30px;
}

.error-span {
    color: #EE5D5D;
    margin-top: 5px;
    position: absolute;
    bottom: -30px;
    height: 25px;
}

.register-form-container {
    width: 500px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-submit-button {
    width: 100%;
    color: #fff;
    background-color: #E2252B;
    border: none;
    height: 4.5rem;
    border-radius: .3rem;
    font-size: 1.1rem;
    cursor: pointer;
    margin-top: 2rem;
}

.register-content-container {
    width: 100%;
    height: 100%;
    /* width: 1440px;
    height: 766px; */
    display: flex;
    background-color: #fff;
}

.register-rigth-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.register-left-container {
    width: 50%;
    background-color: #fff;
    background-image: url(../../utils/images/be_background.png);
    background-size: cover;
}

.register-form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-forgot-password {
    text-align: right;
    width: 70%;
    color: #E2252B;
    cursor: pointer;
    font-size: 1.1rem;
    margin-bottom: 3rem;
    margin-top: 0.8rem;
    font-weight: bold;
}

.register-first-time {
    width: 70%;
    margin-top: 0.2rem;
    font-size: 1.3rem;
    font-weight: bold;
    color: #4D616F;
}

.register-first-time.terms-and-conditions {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 4rem;
}

.register-first-time .sign-up{
    color: #E2252B;
    text-decoration: underline;
    margin-left: 0.35rem;
    cursor: pointer;
    font-weight: bold;
}

.register-title-container {
    width: 70%;
    margin-bottom: 0;
}

.register-title {
    font-size: 4rem;
    margin-bottom: 0;
    font-family: Crimson Text;
}

.register-paragraph {
    font-size: 1.3rem;
    margin: 0;
}

.register-mobile-container {
    width: 100%;
    text-align: center;
}

.mobile-logo {
    margin-top: 10%;
    margin-bottom: 15%;
}

.register-input-container-mobile {
    margin: 0 auto;
    margin-bottom: 5%;
    margin-top: 5%;
    width: 70%
}

.register-first-time-mobile {
    margin: 0px auto;
}

@media (max-width: 2400px) {
 
}

@media (max-width: 1800px) {
    .register-text-input {
        height: 65px;
        font-size: 1.1rem;
    } 

    .register-submit-button {
        height: 65px;
    }

    
    .register-select {
        height: 65px;
    }

    .register-first-time {
        font-size: 1.1rem;
    }

    .register-title {
        font-size: 3rem;
        margin-bottom: 0;
        font-family: Crimson Text;
    }
    
    .register-paragraph {
        font-size: 1.1rem;
        margin: 0;
    }
}


@media (max-width: 1440px) {  
    .register-text-input {
        height: 40px;
        font-size: 1rem;
    }
    .register-submit-button {
        height: 45px;
        margin-top: 0;
    }

    .register-input-container {
        margin: 3px;
        margin-bottom: 15px;
    }

    .error-span {
        font-size: 12px;
    }

    .register-select {
        height: 45px;
    }
        
    .register-first-time {
        font-size: 1rem;
    }

    .register-title {
        font-size: 2.5rem;
        margin-bottom: 0;
        font-family: Crimson Text;
    }
    
    .register-paragraph {
        font-size: 1rem;
        margin: 0;
    }



    .register-first-time.terms-and-conditions{
        margin-bottom: 0.1rem;
    }
}

@media (max-width: 1000px) {
 
}