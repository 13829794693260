.main-page-container {
	position: relative;
 	min-height: 100vh;
}

.content-wrap {
	padding-bottom: 430px;
}

.appWrapper{
	padding: 3rem;
	margin: 0;
	box-sizing: border-box;
	position: relative;
	line-height: 1.5;
	font-size: .9rem;
	box-sizing: border-box;
	height: 100%;
	background: transparent;
}
.lists{
	border: 1px solid #e5e5e5;
	display: flex;
	margin: 0 5vw;
    padding: 0;
	/* width: 90vw; */
	}
.itemList{
	font-family: sans-serif;
	font-weight: 300;
	/* min-width: 300px; */
	display: flex;
	flex-direction: column;
	counter-reset: list;
	z-index: 1;
	align-items: center;

	width: 30%;
	}

	.mytest-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

.item-my-test-title {
	width: 100%;
	list-style: none;
	border-radius: 3px;
	overflow: hidden;
	position: relative;
	-webkit-user-select: none;
	user-select: none;
	display: flex;
	/* cursor: pointer; */
	transition-timing-function: ease;
	transition-duration: .4s;
	transition-property: transform opacity;
	
	padding: 5px;
	margin: 20px 0;
	font-weight: 700;
}

.item-my-test{
	width: 100%;
	list-style: none;
	margin: .6rem 0;
	/* background: #fff; */
	border-radius: 3px;
	overflow: hidden;
	position: relative;
	-webkit-user-select: none;
	user-select: none;
	display: flex;
	cursor: pointer;
	/* box-shadow: 1px 2px 4px rgba(0,0,0,.3); */
	transition-timing-function: ease;
	transition-duration: .4s;
    transition-property: transform opacity;
    padding: 5px;
		/* padding-left: 3vw; */
		padding-left: 10%;

	}
	.content{
		padding: 1rem 2rem 1rem 1rem;
    }
	.item-my-test .touched{
		opacity: .5 !important;
		cursor: -webkit-grabbing;
		cursor: grabbing;
		z-index: -1;
    }
    
	
	.button-save {
		width: 100px;
		/* border: 2px solid #555; */
		border-color:#555 !important;
		left: 50%;
		transform: translateX(-50%);
		position: relative;
	}

	.center {
		left: 50vw !important;
		transform: translateX(-50%) !important;
	}


	
@media (min-width: 2000px) {
	.item-my-test-title {
		font-size: 35px;
		line-height: 40px;
	}

	.item-my-test {
		font-size: 25px;
		line-height: 30px;
	}
}

@media (min-width: 3000px) {
	.item-my-test-title {
		font-size: 65px;
		line-height: 70px;
	}

	.item-my-test {
		font-size: 55px;
		line-height: 60px;
	}
}
