.poem-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.poem-image-container .iam-poem-image {
  width: 200px;
}

.poem-image-title, .poem-image-title p {
  font-family: "Crimson Text";
  font-size: 50px;
  color: #193B54;
  margin: 0;
  margin-top: 25px;
}

.poem-title p em , .poem-title  p u{
  font-family: "Crimson Italic"!important;
} 

.poem-title p strong , .poem-title p b {
  font-family: "Crimson Text"!important;
}

.poem-image-title i {
  font-weight: 500;
  font-family: "Crimson Italic";
}

.poem-image-content {
  position: relative;
}


.poem-image-container .poem-image-content .poem-border {
  width: 230px;
  position: absolute;
  right: -23px;
  bottom: 0;
}

.poem-content-mobile .poem-border {
  left: 50%;
  transform: translateX(-50%);
}