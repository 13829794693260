.login-text-input {
    height: 80px;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    border: 1px solid #e5e5e5;
    font-size: 1.5rem;
}
.login-form {
    color: #193B54;
    font-family: "Roboto Condensed";
    width: 100vw;
    height: 100vh;
}

.login-text-input::placeholder {
    color: #999;
}

.login-input-container {
    display: flex;
    width: 70%;
    margin: 10px;
    flex-direction: column;
    position: relative;
    margin-bottom: 25px;
}

.login-select-container {
    width: 400px;
}

.login-form-container {
    width: 500px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.login-submit-button {
    width: 70%;
    color: #fff;
    background-color: #E2252B;
    border: none;
    height: 4.5rem;
    border-radius: .3rem;
    font-size: 1.1rem;
    cursor: pointer;
    margin-top: 1rem;
}

.login-content-container {
    width: 100%;
    height: 100%;
    /* width: 1440px;
    height: 766px; */
    display: flex;
    background-color: #fff;
}

.login-rigth-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 30px;
}

.login-left-container {
    width: 50%;
    background-color: #fff;
    background-image: url(../../utils/images/be_background.png);
    background-size: cover;
}

.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-forgot-password {
    text-align: right;
    width: 70%;
    color: #E2252B;
    cursor: pointer;
    font-size: 1.1rem;
    margin-bottom: 3rem;
    margin-top: 0.8rem;
    font-weight: bold;
}

.login-first-time {
    width: 70%;
    margin-top: 2rem;
    font-size: 1.3rem;
    font-weight: bold;
    color: #4D616F;
}

.login-first-time .sign-up{
    color: #E2252B;
    text-decoration: underline;
    margin-left: 0.35rem;
    cursor: pointer;
    font-weight: bold;
}

.login-title-container {
    width: 70%;
    margin-bottom: 3rem;
}

.login-title {
    font-size: 4rem;
    margin-bottom: 0.8rem;
    font-family: Crimson Text;
}

.login-paragraph {
    font-size: 1.3rem;
}

.login-mobile-container {
    width: 100%;
    text-align: center;
}

.mobile-logo {
    margin-top: 10%;
    margin-bottom: 15%;
}

.login-mobile-content-container {
 margin: 0 auto;
}

.login-title-container-mobile {
    margin: 0 auto;
}

.login-input-container-mobile {
    margin: 0 auto;
    margin-bottom: 5%;
    margin-top: 5%;
    width: 70%;
}

.login-forgot-password-mobile {
    margin: 0 auto;
}

.loading-mobile {
    position: absolute;
    top: 50%;
    left: 45%;
}

.home-button {
    height: 90px;
    width: 90px;
    border: none;
    border-radius: 50%;
    position: absolute;
    left: 2.2vw;
    top: 2.5vw;
}

.home-button:hover {
    cursor: pointer;
}

@media (max-width: 2400px) {
 
}

@media (max-width: 1800px) {
    .login-text-input {
        height: 65px;
        font-size: 1.1rem;
    } 

    .login-submit-button {
        height: 65px;
    }

    .login-first-time {
        font-size: 1.1rem;
    }

    .login-title {
        font-size: 3rem;
        margin-bottom: 0.8rem;
        font-family: Crimson Text;
    }
    
    .login-paragraph, .login-forgot-password  {
        font-size: 1.1rem;
    }
}


@media (max-width: 1440px) {  
    .login-text-input {
        height: 55px;
        font-size: 1rem;
    }
    .login-submit-button {
        height: 55px;
    }
    
    .login-first-time {
        font-size: 1rem;
    }

    .login-title {
        font-size: 2.5rem;
        margin-bottom: 0.8rem;
        font-family: Crimson Text;
    }
    
    .login-paragraph, .login-forgot-password {
        font-size: 1rem;
    }
}

@media (max-width: 1000px) {
 
}