.task-container {
  margin-top: 30px;
  height: 100%;
  width: 100%;
}

.select-chapter-container {
  padding: 10%;
}

.task-container .title {
  text-align: center;
  padding: 20px;
  margin-top: 40px;
}

.task-select-item {
  color: #193B54;
  font-size: 30px;
  cursor: pointer;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
}


.task-select-item:hover {
  opacity: 0.6;
}

.task-select-searchs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
}

.task-select-searchs .register-select {
  width: 40%;
}

.page-selector-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
}

.page-selector-container .handle-page-button {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.page-selector-container .handle-page-button:hover {
  opacity: .7;
  cursor: pointer;
}