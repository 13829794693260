#display-not {
  display: none;
}

.is-loading {
  display: flex;
  min-width: 1920px;
  min-height: 1080px;
  z-index: 1000;
  background-image: url("../../utils/images/BG.png");
  justify-content: center;
  align-items: center;
}

.not-loading {
  display: none;
}

.chapter-row {
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.assessment-div {
  width: 35vw;
  height: 17vw;
  border: solid;
  border-width: 1px;
  border-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
}

#last-assessment {
  align-items: flex-end;
}

.chapter-div {
  width: 55vw;
  height: 17vw;
  display: flex;
}

.chapter-not-even {
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
}

.chapter-even {
  margin-top: auto;
  margin-left: auto;
  margin-bottom: auto;
}

.assessment-not-even {
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
}

.dot {
  width: 0.7vw;
  height: 0.7vw;
  background: #dcdcdc;
  border-radius: 1vw;
  margin: 0.3vw;
}

.dot-container {
  margin-bottom: auto;
}

#flip-dots {
  transform: scale(-1, 1);
}

.secondary-path {
  position: absolute;
  transform: rotate(0deg);
}

#move-left {
  left: 90vw;
}

#path-done {
  background: #6CD998;
}

.bg-grid{
  background-image: url("../../utils/images/BG.png");
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}


.bg-grid.movement-beland {
  display: block;
}

.chapter-title {
  font-family: Crimson Text !important;
  line-height: 76px;
}

.text-under-title {
  margin-top: 50px;
  width: 70%;
  font-family: Crimson Text;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  /* or 28px */

  text-align: center;
  position: relative;
  color: #193b54;
  left: 50%;
  transform: translateX(-50%);
}

.text-under-title-mobile {
  width: 90%;
  text-align: left;
  justify-content: left;
}

.question-under-title {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 48px;
  text-align: center;

  color: #193b54;

  margin-top: 20px;
  margin-bottom: 0px;
}

.question-under-title-mobile {
  margin-bottom: 50px;
}

.under-title-line-mobile {
  position: relative;
  /* width: 200px; */
  height: 13.38px;
  left: 70%;
  transform: rotate(-176.92deg);
  transform: translateX(-50%);
  top: 15px;
}

.under-title-line-mobile img {
  width: 240px;
}

.cards-container {
  margin: 50px 0px;
}

.blurred {
  height: 500vh;
  width: 100vw;
  position: fixed;
  top: 0%;
  left: 0%;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10000;
}

.under-title-line {
  position: relative;
  width: 365.14px;
  height: 13.38px;
  left: 50%;
  transform: rotate(-176.92deg);
  transform: translateX(-50%);
  margin-top: 15px;
}

.chapter-container {
  display: flex;
  /* flex-direction: column; */
  margin: 0;
  position: relative;
  width: 100%;
  height: 450px;
  padding: 5%;
  /* background: #FFFFFF; */
  /* border: 1px solid #F2F2F2; */
  /* box-sizing: border-box; */
  /* box-shadow: 0px 12px 14px rgba(0, 0, 0, 0.04); */
  /* border-radius: 20px; */
}

.chapter-container .left-side {
  position: relative;
  display: flex;
  width: 50%;
  align-self: flex-start;
  /* border: 2px solid blue; */
  /* height: 100%; */
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  height: 100%;
  /* padding-left: 10vw; */
}

.chapter-container .right-side {
  position: relative;
  display: flex;
  width: 50%;
  height: 100%;
  align-self: flex-end;
  /* border: 2px solid orange; */
  /* height: 100%; */
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  /* padding-right: 10vw; */
}

.self-evaluation {
  position: relative;
  z-index: 20;
}

.self-evaluation-mobile {
  position: relative;
  width: 60%;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: center;
}

.self-evaluation-img {
  position: absolute;
  width: 10vw;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-40%, -45%);
}

.self-evaluation-img-mobile {
  position: absolute;
  width: 30vw;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-40%, -45%);
}

.self-evaluation-circle {
  z-index: 9;
  border-radius: 50%;
  width: 12vw;
  height: 12vw;
  position: relative;
}

.self-evaluation-circle:hover {
  border: 3px solid rgba(25, 59, 84, 0.8);
}

.self-evaluation-circle-mobile {
  z-index: 9;
  border-radius: 50%;
  width: 9rem;
  height: 9rem;
  position: relative;
}

.self-evaluation-circle-mobile:hover {
  border: 3px solid rgba(25, 59, 84, 0.8);
}

.right-side .self-evaluation {
  position: absolute;
  top: -20%;
  right: 20%;
  z-index: 50;
}

.left-side .self-evaluation {
  position: absolute;
  top: -20%;
  left: 20%;
}

.self-evaluation .background {
  border-radius: 40px;
  height: 200px;
  left: 240px;
  top: 480px;
  width: 250px;
}

.self-evaluation-mobile .background {
  width: 80%;
  border-radius: 10% !important;
}

.self-evaluation .chart-container {
  background-color: #ffffff;
  border-radius: 50%;
  position: relative;
  top: -20px;
}

.self-evaluation-mobile .chart-container {
  background-color: #ffffff;
  border-radius: 50%;
  position: relative;
}

.green-tick-image {
  position: absolute;
  top: -20px;
  right: -20px;
  margin-top: 10px;
  margin-right: 10px;
  z-index: 100;
  background-color: #ffffff;
  border-radius: 30%;
}

.card-container {

}

.card-container-mobile .card-left-side {
  width: 100%;
  padding: 30px;
}

.card-container-mobile .card-right-side {
  width: 100%;
  padding: 40px 0px 0px 0px;
}

@media (max-width: 1400px) {
  .chapter-container {
    height: 300px;
  }
  .card-data-chapter {
    font-size: 15px !important;
    margin: 0 !important;
  }
  .card-data-chapter-title {
    font-size: 20px !important;
  }
  .card-container {
    min-width: 300px !important;
  }

  .self-evaluation .background {
    width: 175px !important;
    height: 175px !important;
  }

  .self-evaluation .chart-container {
    top: 0px;
  }

  .card-data-button {
    margin: 0 !important;
    height: 35px !important;
  }
  .self-evaluation-circle {
    width: 13vw !important;
    height: 13vw !important;
  }
  .green-tick-image {
    width: 75px !important;
  }
}

@media (max-width: 900px) {
  .card-data {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .card-data-chapter {
    font-size: 15px !important;
    margin: 0 !important;
  }
  .card-data-chapter-title {
    font-size: 20px !important;
  }
  .self-evaluation .background {
    width: 125px !important;
    height: 125px !important;
  }
  .card-container {
    height: 22.5vw !important;
  }

  .self-evaluation .chart-container {
    top: 0px;
  }
  .green-tick-image {
    width: 50px !important;
  }
  .self-evaluation-img {
    width: 15vw;
  }
}
.first-self-evaluation-circle {
  animation-name: stretch;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

.self-evaluation-mobile .self-evaluation-img {
  width: 21vw;
}
.chapter-image-container {
  width: 100%;
}

.chapter-image-container .chapter-image {
  height: 100%;
};

@keyframes stretch {
  0% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(1.3);
  }
}


@media (min-width: 2000px) {
  .self-evaluation .background {
    border-radius: 40px;
    height: 300px;
    left: 240px;
    top: 480px;
    width: 350px;
  }
  .poem-title {
    font-size: 50px!important;
    line-height: 110px;
  }
  .text-under-title {
    margin-top: 75px;
    font-size: 35px!important;
  }

  .question-under-title {
    font-size: 50px;
    line-height: 63px;
    margin-top: 35px;
  }

  .card-data-chapter {
    font-size: 35px !important;
    margin: 0 !important;
  }
  .card-data-chapter-title {
    font-size: 40px !important;
  }

  .card-data-button {
    width: 240px!important;
    font-size: 35px!important;
  }
}

@media (min-width: 3000px) {
  .poem-title {
    font-size: 75px!important;
    line-height: 160px;
  }
  .text-under-title {
    margin-top: 95px;
    font-size: 55px!important;
  }

  .question-under-title {
    font-size: 70px;
    line-height: 83px;
    margin-top: 55px;
  }

  .card-data-chapter {
    font-size: 55px !important;
    margin: 0 !important;
  }
  .card-data-chapter-title {
    font-size: 60px !important;
  }

  .card-data-button {
    width: 360px!important;
    font-size: 55px!important;
    height: 150px!important;
  }

  .self-evaluation .background {
    border-radius: 40px;
    height: 400px;
    width: 450px;
  }
}
