.create-chapter-container .text-input {
  width: 300px;
}

.drag-chapters-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.drag-chapters-container .task-list {
  width: 50%;
  height: 75vh;
  overflow: auto;
  padding-right: 10px;
}

.drag-chapters-container .fields-container {
  width: 100%;
  position: relative;
  height: 80vh;
  overflow: auto;
  display: flex;
}

.chapter-field {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  margin: 20px 0;
 
}
.col-wrapper {
  margin-right: 20px;
  width: calc(20% - 10px);
}
.chapter-field h5 {
  border-bottom: 1px solid #e5e5e5;
}

.task-item {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}

.drag-chapters-container .task-list-wrapper::-webkit-scrollbar {
  width: 5px; }
.drag-chapters-container .task-list-wrapper::-webkit-scrollbar-track {
  background: #EEE;
  border-radius: 0 5px 5px 0; }
.drag-chapters-container .task-list-wrapper::-webkit-scrollbar-thumb {
  background: #AAA;
  border-radius: 2.5px; }
.drag-chapters-container .task-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: #23DC88; }

  .task-item {
    font-size: 15px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    background-color: white;
}

.task-item:hover {
    cursor: pointer;
}


.task-list-wrapper {
  /* position: absolute; */
  border: none;
  top: 0;
  left: 0;
  /* width: 45%;  */
  height: 80vh;
  overflow: auto;
}

.chapter-field.task-list-container {
  border: none;
}

.inputs-container {
  display: flex;
  justify-content: space-between;
}

.create-chapter-buttons {
  display: flex;
}

.create-chapter-buttons .create-chapter-button {
  padding: 10px 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin: 0 10px;
  height: 45px;
  cursor: pointer;
}

.create-chapter-buttons .create-chapter-button:hover {
  opacity: .5;
}

.main-image-container.crete-chapter-container {
  width: 100px;
  height: 100px;
  margin-bottom: 0;
}

.text-inputs-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 20px;
}

.create-left-container {
  display: flex;
}