.line-input {
  border: none;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  font-size: 20px;
  height: 40px;
}

.line-input:focus {
  outline: none;
}

.line-input.text-area.disabled::placeholder {
  color: transparent;
}