* {
  font-family: "Roboto Condensed";
}

.bg-nate-container {
  position: absolute;
}
.font-crim {
  font-family: "Crimson Text";
}

.font-crim-italic {
  font-family: "Crimson Italic";
}
.text-input {
  height: 40px;
  border-radius: 5px;
  width: "100%";
  border: 1px solid #e5e5e5;
  padding-left: 5px;
}

.input-container {
  display: flex;
  width: 300px;
  margin: 20px 10px;

  flex-direction: column;
}

.select-container {
  width: 400px;
}

.form-container {
  width: 500px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.submit-button {
  width: 100%;
  height: 40px;
  color: #fff;
  background-color: cadetblue;
}

.navbar-nav {
  font-size: 20px;
}

.btn-primary {
  background-color: #e2252b;
  border-radius: 47px;
  border-color: #e2252b;
  padding: 13px 38px;
}

.btn-primary:hover {
  background-color: #ffffff;
  border-color: #e2252b;
  color: #e2252b;
}

.btn-title {
  font-size: 29px;
}

.btn-outline-sm {
  padding: 4px 12px;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #ffffff;
  font-size: 20px;
}

.background-section {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-around;
  height: 100vh;
  min-height: auto;
  margin: 0px auto;
  overflow: hidden;
  align-items: center;
  padding: 0px;
}

.sel.background-section {
  height: 80vh;
  overflow: unset;
}

.transformative-sel {
  padding-top: 300px;
  padding-bottom: 70px;
  background-color: #fafafa;
}

.book {
  height: 65vh;
}

.bg-grid {
  background-image: url("../../utils/images/BG.png");
  width: 100%;
  height: 100%;
}

.bg-trace {
  background-image: url("../../utils/images/trace.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-gang {
  background-image: url("../../utils/images/Gang.png");
  width: 59%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-book {
  background-image: url("../../utils/images/book.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.title-text {
  font-size: 65px;
  line-height: 71px;
  color: #e51b1c;
}

h2.title-text {
  font-size: 45px;
  line-height: 49px;
  color: #6c99d9;
}

.subtitle-text {
  font-size: 20px;
  line-height: 30px;
  color: #193b54;
}

.movement-be {
  height: 70vh;
}

.sel .subtitle-text {
  font-size: 25px;
  line-height: 37px;
}

.sel .subtitle-text ~ p {
  font-size: 20px;
  line-height: 30px;
}

.hand-position {
  position: absolute;
  top: -100px;
}

.justice-position {
  position: absolute;
  width: 400px;
  left: calc(50% - 200px);
  bottom: -200px;
}

.first-title {
  color: #204e6f;
  font-size: 25px;
  line-height: 37px;
}

.last-title {
  color: #204e6f;
  font-size: 18px;
  line-height: 27px;
  margin-top: 70px;
  text-align: center;
}

.sel-card {
  box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 30px 40px 40px 40px;
  border: 0;
  height: 100%;
}

.sel-card .title {
  font-size: 23px;
  line-height: 35px;
  font-weight: bold;
  color: #204e6e;
  font-family: "Roboto Condensed";
}
.tip-text {
  font-size: 18px;
  line-height: 20px;
  color: #204e6f;
}

.nate-section {
  padding-top: 90px;
  padding-bottom: 90px;
}

.nate-section h3 {
  font-size: 45px;
  line-height: 49px;
}

.nate-section img {
  object-fit: contain;
}

.yellow-section {
  height: 40vh;
  background-color: #f9da6d;
}

.yellow-section h3 {
  color: #204e6f;
  font-size: 32px;
  line-height: 35px;
}

.partners-section {
  padding-top: 100px;
  padding-bottom: 80px;
}

.partners-section h4 {
  font-size: 45px;
  line-height: 49px;
  color: #193b54;
}

footer {
  background-color: #f7f7f7;
  padding-top: 44px;
  padding-bottom: 19px;
  color: #204e6f;
}

.footer-text {
  font-size: 10px;
  line-height: 11px;
  color: #999999;
}

ul.unstyled-list {
  list-style: none;
}

li,
li a {
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 20px;
  color: #204e6f;
}

.owner-text {
  font-size: 20px;
  line-height: 24px;
}

.user-img {
  height: 50px;
  width: 50px;
  border-radius: 48px;
}

#user-dropdown {
  padding: 13px 13px;
}

.cl-white,
.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}

.cl-dark-blue {
  color: #193b54;
}

.footer-container {
  width: 100vw;
  margin-top: auto;
  height: 30vh;
}

.social-banner {
  padding-bottom: 200px;
}

.no-wrap {
  flex-wrap: nowrap;
}

.padding-20 {
  padding: 20px;
}

.width-130 {
  width: 130px;
}

.height-130 {
  height: 130px;
}

.transformative-sel .row {
  flex-wrap: nowrap;
}

@media (min-width: 576px) {
}

@media (min-width: 769px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (max-width: 1440px) {
  .title-text {
    font-size: 32px;
    line-height: 35px;
  }

  .subtitle-text {
    font-size: 15px;
    line-height: 22px;
  }
}

@media (min-width: 1602px) and (max-width: 1919px) {
  /* body {
		max-width: 1600px;
  } */
  .bg-book {
    background-size: cover;
  }

  .justice-position {
    position: absolute;
    width: 350px;
    left: calc(50% - 175px);
    bottom: -175px;
  }

  .social-banner {
    padding-bottom: 200px;
  }
}

@media (min-width: 1440px) and (max-width: 1601px) {
  /* body {
		max-width: 1440px;
  } */
  .justice-position {
    position: absolute;
    width: 300px;
    left: calc(50% - 150px);
    bottom: -50px;
  }

  .social-banner {
    padding-bottom: 100px;
  }
  .transformative-sel {
    padding-top: 100px;
  }
}

@media (min-width: 1025px) and (max-width: 1439px) {
  /* body {
		max-width: 1025px;
  } */
  .transformative-sel {
    padding-top: 150px;
  }
  .transformative-sel .col-11 {
    font-size: 15px;
  }

  /* .background-section {
    height: 55vh;
  } */
  .hand-position {
    transform: scale(0.5);
    top: 100px;
    left: -70px;
  }

  .sel.background-section {
    height: 45vh;
  }

  .justice-position {
    position: absolute;
    width: 250px;
    left: calc(50% - 125px);
    bottom: -125px;
  }
  .social-banner {
    padding-bottom: 100px;
  }
  .book {
    height: 25vh;
  }

  .bg-book {
    background-size: cover;
    background-position: center;
  }

  .sel .subtitle-text {
    font-size: 14px;
    line-height: 18px;
  }

  .sel .subtitle-text ~ p {
    font-size: 12px;
    line-height: 15px;
  }
  .title-text {
    font-size: 40px;
    line-height: 44px;
  }

  .subtitle-text {
    font-size: 20px;
    line-height: 25px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .transformative-sel {
    padding-top: 150px;
  }

  .footer-text.text-center {
    text-align: left !important;
  }
  .assessment-div {
    height: unset !important;
  }
  .bg-grid {
    display: none;
    min-height: unset !important;
  }
  .transformative-sel .row {
    flex-wrap: wrap;
  }
  /* body {
		max-width: 769px;
  } */

  .background-section {
    height: 55vh;
  }

  .sel.background-section {
    height: 50vh;
  }

  /* .movement-be {
    height: 40vh;
  } */
  .sel-card {
    padding: 30px 15px 40px 15px;
  }

  .sel .subtitle-text {
    font-size: 14px;
    line-height: 18px;
  }

  .sel .subtitle-text ~ p {
    font-size: 12px;
    line-height: 15px;
  }

  .justice-position {
    position: absolute;
    width: 250px;
    left: calc(50% - 125px);
    bottom: -125px;
  }

  .social-banner {
    padding-bottom: 100px;
  }

  .hand-position {
    transform: scale(0.5);
    left: -60px;
    top: 0px;
  }

  .book {
    height: 30vh;
  }

  .bg-book {
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 768px) {
  .footer-text.text-center {
    text-align: left !important;
  }
  .assessment-div {
    height: unset !important;
  }
  .bg-grid {
    display: none;
    min-height: unset !important;
  }

  .bg-gang {
    background-size: contain;
  }
  /* .background-section {
    height: 55vh;
  } */
  /* .sel.background-section {
    height: 50vh;
  } */
  .transformative-sel .row {
    flex-wrap: wrap;
  }
  .justice-position {
    position: absolute;
    width: 250px;
    left: calc(50% - 125px);
    bottom: -125px;
  }

  .sel .subtitle-text {
    font-size: 14px;
    line-height: 18px;
  }

  .sel .subtitle-text ~ p {
    font-size: 12px;
    line-height: 15px;
  }

  .social-banner {
  }
  /* .book {
    height: 28vh;
  } */
  .bg-book {
    background-size: cover;
    background-position: center;
  }

  .hand-position {
    top: -133%;
    transform: scale(0.75);
    right: 14%;
  }

  /* MOBILE STYLES */

  .hand-position {
    width: 100%;
    transform: rotate(30deg) !important;
    padding: 10px 40px;
  }
  .bg-nate-container {
    position: relative !important;
  }

  .bg-gang {
    width: 100%;
    left: 0;
  }

  .title-text {
    color: #204e6e;
    width: 80%;
  }
  .subtitle-text {
    font-weight: bold;
    color: #193b54;
  }

  .bg-grid {
    height: 250%;
  }

  .hand-container {
    padding-bottom: 150px;
  }

  h2.title-text {
    font-size: 25px;
  }
  .social-banner {
    padding-bottom: 50px;
  }
  .sel.background-section {
    height: 60vh !important;
  }

  .justice-position {
    position: absolute;
    width: 150px;
    left: calc(50% - 75px);
    bottom: -75px;
  }

  .sel .title-text {
    color: #e2252b;
    width: 100%;
    font-size: 25px;
    text-align: left;
  }

  .sel .subtitle-text {
    text-align: left;
  }

  .transformative-sel {
    padding-top: 100px;
  }

  .transformative-sel h4 {
    font-size: 17px;
    line-height: 20px;
    padding: 0 15px;
  }

  .transformative-sel .container-fluid {
    padding: 0;
  }

  .transformative-sel .container-fluid .sel-card {
    padding: 0;
    background-color: #fafafa;
    border: none;
  }

  .transformative-sel .row {
    background: #fafafa;
  }

  .sel-card .title {
    background-color: #fafafa;
    font-size: 23px !important;
    font-family: "Roboto Condensed" !important;
    font-weight: bold;
    padding: 15px 0;
  }
  .sel-card .card-body {
    padding: 20px 15px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .tip-text {
    font-size: 16px;
  }

  .tip-text .row .col-11,
  .tip-text .row .col-1 {
    padding: 0;
  }

  .transformative-sel .row .col-12 {
    margin: 20px 0;
    padding: 0;
  }
  .transformative-sel .row {
    height: unset;
    margin: 0;
  }
  .last-title {
    margin-top: 20px;
    font-size: 13px;
    line-height: 15px;
    text-align: left;
    width: 100%;
    padding-left: 20px;
  }

  .nate-section img {
    display: none;
  }

  .nate-section h3.title-text {
    font-size: 30px;
    line-height: 31px;
    width: 100%;
  }

  .nate-section h3.title-text:first-child {
    color: #e2252b;
  }

  .nate-section .anchor-container {
    display: flex;
    justify-content: center;
  }

  .nate-section .anchor-container a {
    border-radius: 25px;
    font-weight: bold;
    padding: 10px 30px;
    font-size: 18px;
    background-color: #e2252b;
    border: none;
  }
  .yellow-section {
    height: 40vh !important;
  }
  .yellow-section .text-center {
    margin-bottom: 10px !important;
  }
  .yellow-section h3 {
    font-size: 16px;
    line-height: 108.54%;
    padding: 0 15px;
  }
  .partners-section {
    padding-top: 30px;
  }

  .partners-section h4 {
    margin-bottom: 0px !important;
  }

  .partners-section .col-3 img {
    width: 100%;
    object-fit: contain;
    height: 50px;
  }

  .footer-container {
    position: relative;
    height: 306px;
  }

  .footer-container .footer-selection {
    display: none;
  }

  .footer-container .social-networks {
    position: absolute;
    top: 45px;
    right: 20px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-container .social-networks ul {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-container .social-networks ul li {
    margin-bottom: 0;
  }
  .footer-container .social-networks ul li img {
    width: 30px;
    height: 30px;
  }

  .footer-container .owner-text b {
    color: #204e6f;
  }
}

@media (max-width: 414px) {
  /* .background-section {
    height: 42vh;
  } */
  /* .book {
    height: 35vh;
  } */
  .transformative-sel .row {
    flex-wrap: wrap;
  }
  .movement-be {
    height: unset;
  }
  .hand-position {
    position: unset;
    transform: scale(0.75);
  }
  .sel.background-section {
    height: 120vh;
  }
  .sel-card {
    padding: 30px 15px 40px 15px;
  }
  .bg-book {
    background-size: cover;
    background-position: center;
  }
  .nate-section {
    padding-top: 0;
  }
  .nate-section img {
    object-fit: contain;
  }
  .yellow-section {
    height: 60vh;
  }
}

@media (max-width: 375px) {
  .background-section {
    height: 80vh;
  }
}

@media (min-width: 2000px) {
  ul.unstyled-list li,
  ul.unstyled-list li a {
    font-size: 25px !important;
    line-height: 30px;
  }
  .footer-text {
    font-size: 14px;
    line-height: 16px;
  }
  .footer-container img {
    height: 60px;
  }
  .footer-container .social-network-image {
    height: 40px !important;
  }
  .title-text {
    font-size: 80px;
    line-height: 85px;
  }
  .subtitle-text {
    font-size: 25px;
    line-height: 30px;
  }
  h2.title-text {
    font-size: 85px;
    line-height: 90px;
  }

  .sel .subtitle-text {
    font-size: 35px;
    line-height: 40px;
    margin: 60px 0;
  }

  .sel .subtitle-text ~ p {
    font-size: 30px;
    line-height: 35px;
    margin: 60px 0;
  }
}

@media (min-width: 3000px) {
  ul.unstyled-list li,
  ul.unstyled-list li a {
    font-size: 45px !important;
    line-height: 50px;
  }

  .owner-text {
    font-size: 45px !important;
    line-height: 50px;
  }
  .footer-text {
    font-size: 25px;
    line-height: 27px;
  }
  .footer-container {
    height: 25vh;
    min-height: 500px;
  }
  .footer-container img {
    height: 100px;
  }

  .footer-container .social-network-image {
    height: 60px !important;
  }
  .title-text {
    font-size: 100px;
    line-height: 110px;
  }
  .subtitle-text {
    font-size: 40px;
    line-height: 45px;
  }

  h2.title-text {
    font-size: 110px;
    line-height: 120px;
  }

  .sel .subtitle-text {
    font-size: 50px;
    line-height: 55px;
    margin: 80px 0;
  }

  .sel .subtitle-text ~ p {
    font-size: 45px;
    line-height: 50px;
    margin: 80px 0;
  }
  .transformative-sel .row {
    font-size: 50px;
    line-height: 70px;
  }

  .mb-5.first-title,
  .last-title {
    font-size: 60px;
    margin-bottom: 100px !important;
  }

  .last-title {
    font-size: 50px;
    margin-bottom: 50px !important;
  }

  .sel-card .title {
    font-size: 60px !important;
    line-height: 80px !important;
  }

  .nate-section h3 {
    font-size: 115px;
    line-height: 130px;
  }

  .anchor-container a {
    font-size: 50px;
    padding: 20px;
    border-radius: 20px;
    margin-top: 50px;
  }

  .yellow-section h3 {
    font-size: 60px;
    line-height: 70px;
  }

  .partners-section h4 {
    font-size: 80px;
    line-height: 90px;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 3000px !important;
  }
  .partner-icon-container img {
    height: 300px;
    width: 300px;
    object-fit: contain;
  }

  .transformative-sel .row img.icon-image {
    height: 50px;
    width: 50px;
  }
  
}

.poem-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
