.my-workshop-container {
  width: 100vw;
  height: 100vh;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-workshop-list-container {
  width: 90%;
  max-width: 1024px;
}

.my-workshop-list-container .list-item-text {
  width: 25%!important;
}

@media (min-width: 2000px) {
  .dashboard-list-container {
    font-size: 30px;
  }
  .my-workshop-list-container {
    margin-top: 120px;
  }
}

@media (min-width: 3000px) {
  .dashboard-list-container {
    font-size: 50px;
  }
  .my-workshop-list-container {
    margin-top: 160px;
  }
}
