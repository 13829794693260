.modal-next-button {
    position: absolute;
    width: 120.14px;
    height: 47.72px;
    left: 50%;
    top: 80%;

    background: #E2252B;
    border-radius: 31.5452px;
    transform: translateX(-50%);
}

.modal-next-button span{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);

    font-family: "Crimson Text";
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    
    text-align: center;
    
    color: #FFFFFF;
}

.modal-next-button:hover {
    cursor: pointer;
}

.autoEvaluation-modal-container {
    position: fixed;
    width: 680px;
    height: 500px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background: #FFFFFF;
    border-radius: 20px;
    z-index: 100;
}

.autoEvaluation-modal-container-mobile {
    position: fixed;
    width: 90vw;
    height: 80vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background: #FFFFFF;
    border-radius: 20px;
    z-index: 100;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.autoEvaluation-modal-container .title {
    position: relative;
    width: 100%;
    height: 43px;

    font-family: "Crimson Text";
    font-style: italic;
    font-weight: normal;
    font-size: 35px;
    text-align: center;

    margin-top: 50px;
}

.autoEvaluation-modal-container .subtitle {
    position: relative;
    width: 100%;
    height: 30px;

    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */

    text-align: center;

    color: #4D616F;

    margin-top: 15px;
}

.autoEvaluation-modal-container-mobile .title-mobile {
    position: relative;
    width: 100%;
    height: 43px;

    font-family: "Crimson Text";
    font-style: italic;
    font-weight: normal;
    font-size: 35px;
    text-align: center;

    margin-top: 50px;

    font-size: 30px!important;
    color: #193B54!important;
    margin-bottom: 20px;
    font-family: "Crimson Italic"!important;
    z-index: 10!important;
    text-align: center;
}

.autoEvaluation-modal-container-mobile .subtitle {
    position: relative;
    width: 100%;
    height: 30px;

    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */

    text-align: center;

    color: #4D616F;

    margin-top: 15px;
}

.autoEvaluation-modal-container .chart, .chart.autoEvaluation-modal-container-mobile .chart{
    position: relative;
    left: 50%;
    /* top: 50%; */
    width: 200px;
    height: 200px;
    transform: translate(-50%, 10%);
}

.autoEvaluation-modal-container-mobile .chart{
    position: relative;
    top: 60px;
}