*,*::after, *::before {
  padding:0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background-color: #fff;
}

.z-0 {
  z-index: 0!important;
}

@font-face {
  font-family: "Crimson Text";
  src: url(./utils/fonts/CrimsonText-Regular.ttf);
}

@font-face {
  font-family: "Crimson Italic";
  src: url(./utils/fonts/CrimsonText-Italic.ttf);
}


@font-face {
  font-family: "Roboto Condensed";
  src: url(./utils/fonts/RobotoCondensed-Regular.ttf);
}