.admin-container {
  width: 100vw;
  height: 100vh;
  padding-top: 60px;
  padding-left: 200px;
  display: flex;
}
.admin-container .sidebar {
  width: 200px;
  height: 100vh;
  left: 0;
  top: 60px;
  border-right: 1px solid #e5e5e5;
  position: fixed;
}

.admin-container .right-container {
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  overflow: auto;
}

.admin-container .right-container .list-item-text {
  width: 20%;
}

.admin-container .right-container .list-item-text.button-text {
  cursor: pointer;
}

.admin-container .right-container .list-item-text.button-text:hover {
  color: #999;
}

.admin-container .loading-container {
  width: 100%;
  height: 100vh;
}

.admin-container .collapse-item {
  padding-left: 40px;
}

.admin-container .selected {
  background-color: #e5e5e5;
}

.dashboard-list-item {
  border: 1px solid #e5e5e5;
  padding: 15px;
  border-radius: 10px;
  display: flex;
}

.list-item-icon {
  cursor: pointer;
}

.color-transparent {
  color: transparent !important;
}

.modal-body-container {
  outline: none;
  background-color: #fff;
  border-radius: 10px;
  max-height: 80%;
  overflow: auto;
  padding: 15px;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container::-webkit-scrollbar {
  width: 5px;
}
.modal-container::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 0 5px 5px 0;
}
.modal-container::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2.5px;
}
.modal-container::-webkit-scrollbar-thumb:hover {
  background: #23dc88;
}

.modal-body {
  border: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 15px;
  padding: 30px;
  width: 300px;
  margin: auto;
  flex: none;
}

.modal-title {
  font-weight: bold;
  font-size: 25px;
}

.modal-button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  margin-bottom: 20px;
}

.modal-button {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background-color: #fff;
  padding: 15px;
  width: 40%;
  cursor: pointer;
}

.modal-button:hover {
  opacity: 0.6;
}

.edit-button {
  margin-left: 30px;
  width: 300px;
}

.modal-button-container .modal-button:hover {
  background-color: #e5e5e5;
}

.edit-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.main-image-container input {
  display: none;
}

.main-image-container {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  padding: 10px;
  cursor: pointer;
}

.main-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.edit-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
}

.edit-container-student {
  flex-direction: column;
  align-items: center;
}

.data-edit-container {
  display: flex;
  flex-wrap: wrap;
}

.edit-container .input-container {
  margin: 0;
  padding: 20px 10px;
}

.edit-container-student .main-image-container {
  display: initial;
  border-radius: 50%;
  border: none;
  padding: 00px;
}

.edit-container-student .user-image-container img,
.edit-container-student .user-image-container {
  border-radius: 50%;
  margin-bottom: 25px;
}

.edit-container.edit-container-student .input-container {
  padding: 10px;
  border: none;
}

.edit-input-label {
  text-transform: capitalize;
}

.edit-title {
  width: 100%;
  font-weight: bold;
}

.date-picker {
  width: 100%;
}

.time-class {
  font-size: 12px;
}

.react-datepicker__time-container {
  width: 8em !important;
  font-size: 1.2em !important;
}
.react-datepicker-time__header {
  font-size: 1em !important;
}
.react-datepicker-time__header--time {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.react-datepicker__time-box {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
}
.react-datepicker__time-list {
  padding: 0 !important;
}

.user-information-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.user-information-container .user-image-container img {
  object-fit: cover;
}

.user-information-container .user-image-container {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
}

.main-image-container.create {
  width: 300px;
  height: 300px;
  border-radius: 150px;
  border: 1;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  padding: 0;
}

.main-image-container.create img {
  object-fit: cover;
}

.create-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin-top: 50px;
}

.class-list-container {
  max-width: 800px;
  margin-top: 50px;
}

.class-list-container .register-input-container.list-select {
  height: 50px !important;
  width: 100%;
  margin-left: 0;
}

.class-list-table-container {
  margin-top: 40px;
}

.information-container {
  display: flex;
  flex-wrap: wrap;
}

.information-container .w-50 {
  width: 45% !important;
}

.w-70 {
  width: 70% !important;
}

.input-container {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 10px;
}

.input-container.assing-students {
  width: 200px;
}

.information-container .edit-input-label {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}

.profile-image-url-container {
  display: flex;
  flex-direction: column;

  margin-left: 10px;
}

.user-image-container {
  border: 1px solid #e5e5e5;
  width: 200px;
  height: 200px;
}

.user-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.action-buttons {
  width: 100%;
  display: flex;
}

.user-action-buttons {
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  margin-right: 20px;
}

.user-action-buttons.disabled {
  opacity: 0.5;
}

.user-action-buttons:hover {
  opacity: 0.5;
  cursor: pointer;
}

.list-item-button {
  cursor: pointer;
}

.list-item-button:hover {
  opacity: 0.5;
}

.student-chapter-containers {
  padding: 20px;
}

.student-chapter-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #e5e5e5;
  margin: 10px 0;
  border-radius: 10px;
}

.student-chapter-row .student-chapter-text-container {
  width: 35%;
}

.student-chapter-row .unblock-chapter-button {
  width: 20%;
  cursor: pointer;
}

.student-chapter-row .chapter-void {
  width: 20%;
}

.student-chapter-row .unblock-chapter-button:hover {
  color: #aaa;
}

.student-chapter-row.chapter-inprogress {
  background-color: yellow;
}

.student-chapter-row.chapter-completed {
  background-color: #23dc88;
}

.student-chapter-row.chapter-bloqued {
  background-color: #e5e5e5;
}

.student-chapter-text-container {
  display: flex;
}

.student-chapter-text {
  margin-left: 10px;
}

.counselor-prifle-image-container {
  width: 100%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
}

.class-item {
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  height: 60px;
  width: 400px;
  justify-content: space-between;
  padding: 0 15px;
}

.class-item.assign-students {
  width: 100%;
}

.class-item .add-button {
  cursor: pointer;
  font-weight: bold;
}

.class-item .add-button.invited {
  cursor: auto;
  font-weight: bold;
}
.class-item .add-button.invited:hover {
  color: #000;
}

.class-item .add-button:hover {
  color: #e5e5e5;
}

.classes-container {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  overflow: auto;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 40px;
}

.all-classes-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  position: relative;
}

.assign-students-container {
  width: 60%;
}

.all-classes-container.assign-students {
  width: 100%;
  display: block;
  max-height: 80vh;
  overflow-y: auto;
}

.all-classes-container.assign-students.invited {
  width: 35%;
  padding-top: 50px;
}

.assign-students-buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}

.assign-students-buttons {
  cursor: pointer;
  font-weight: bold;
  height: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.assign-students-buttons.next-button {
  margin-left: auto;
}

.assign-students-buttons:hover {
  color: #999;
}
.all-classes-container.assign-students .class-item {
  width: 100%;
}

.mh-10 {
  margin: 0 10px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.save-question-button {
  padding: 20px;
  border: 1px solid #e5e5e5;
  width: 200px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10px;
}

.save-question-button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.assign-students-top-bar {
  display: flex;
  align-items: center;
}

.assign-students-top-bar .assign-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  height: 50px;
  padding: 0 20px;
  cursor: pointer;
  margin-left: 10px;
}

.assign-students-top-bar .assign-button:hover {
  opacity: 0.6;
}

.assign-students-top-bar .text-input {
  margin-left: 15px;
  height: 50px;
  padding-left: 10px;
}

.question-container {
  padding: 15px;
}

.input-container.question-input-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.question-title {
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.answer-input-button-container {
  position: relative;
}

.answer-input-button-container input {
  padding-right: 35px;
}

.questions-container .text-input {
  width: 200px;
  padding-left: 10px;
}

.questions-container.w-100 {
  margin-bottom: 10px;
}

.questions-container.w-100 .text-input {
  width: 100%;
}

.answer-input-button-container .save-answer-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.answer-input-button-container .save-answer-button:hover {
  color: #e5e5e5;
}

.invite-by-mail-modal {
  padding: 20px;
  width: 300px;
}

.invite-by-mail-modal .invite-button {
  padding: 10px 20px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  margin-top: 20px;
}

.invite-button {
  font-weight: bold;
  cursor: pointer;
}

.invite-button:hover {
  opacity: 0.6;
}

.invite-by-mail-modal .text-input {
  width: 100%;
}

.invite-by-mail-modal .invite-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.loading-container.invite-by-mail-modal {
  height: auto;
}

.error-label {
  font-size: 12px;
  color: red;
}

.workshop-date-container {
  padding: 15px;
}

.workshop-details-container {
}

.workshop-details-container.date {
  flex-direction: column;
}

.workshop-details-container .zoom-link {
  font-size: 22px;
}

.workshop-details-container .workshop-title {
  font-size: 25px;
}

.workshop-details-container .workshop-date {
  font-size: 20px;
  margin: 0 10px;
}

.workshop-date {
  font-size: 12px;
}

.workshop-title {
  font-weight: bold;
  font-size: 18px;
}

.zoom-link {
  color: blue;
  font-size: 14px;
  cursor: pointer;
}

.workshop-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.workshop-header-container.add-class {
  position: fixed;
  bottom: 0;
  height: 80px;
}

.add-class-container {
  padding-bottom: px;
}

.invite-input-container {
  position: relative;
}

.invite-input-container .text-input {
  padding-right: 40px;
}

.invite-input-container .invite-add-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.invite-input-container .invite-add-button:hover {
  opacity: 0.6;
}

.email-list-container {
  display: flex;
  flex-direction: column;
}

.border-none {
  border: none !important;
}

.margin-none {
  margin: 0 !important;
}

.workshop-classes {
  width: 70% !important;
}

.student-report-select {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.student-report-select .register-select {
  width: 100%;
}

.student-report-select .text-input {
  width: 120px;
  margin-left: 20px;
}

.chapter-report-student {
  padding: 10px;
  height: 55vh;
}

.chapter-report-student .chapter-report-student-content {
  display: flex;
  height: 100%;
  border: 1px solid #e5e5e5;
}

.chapter-report-student-content .list-chapters-container {
  width: 30%;
  height: 100%;
}

.competencies-grapic {
  width: 70%;
  padding: 20px;
}

.chapter-report-student-content .report-task-container {
  width: 70%;
  overflow: hidden;
  padding: 10px 30px 0;
}

.workshop-invited {
  width: 40% !important;
}

.report-task-item {
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 10px;
  position: relative;
  z-index: 100;
}

.report-task-item .option-menu {
  left: 100%;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.option-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.option-menu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.option-menu a:hover {
  background-color: #fff;
}

/* Show the dropdown menu on hover */
.report-task-item:hover .option-menu {
  display: block;
  color: #000;
}
.report-task-item .option-menu .option-menu-item {
  border: 1px solid #e5e5e5;
  padding: 10px;
}

.report-task-item .option-menu .option-menu-item:hover {
  opacity: 0.7;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.report-task-item:hover .dropbtn {
  background-color: #3e8e41;
}

.report-task-item:hover {
  opacity: 0.7;
}

.report-task {
  background-image: none;
  width: 100%;
  height: 100%;
}

.report-task .poem-content {
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}

.report-alert-button {
  height: 50px;
  width: 50px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}

.report-alert-button.selected {
  background-color: red;
}

.report-footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.counselor-comment-input {
  width: 40vw;
  resize: none;
  height: 200px;
  padding: 10px;
}

.send-review-button {
  width: 120px;
  height: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-info-container {
  display: flex;
  padding: 20px;
}

.profile-info-container .student-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 1px solid #e5e5e5;
  margin-right: 20px;
}

.search-field {
  width: 200px;
  padding: 15px;
  margin-bottom: 10px;
}

.student-container {
  height: 80px;
}

.details-invited {
  background: #eee;
}

.school-details-row {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 48%;
}

.school-details-text-label {
  color: #999;
  font-weight: bold;
}

.school-details-text {
  color: #000;
}

.school-information-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.graphyc-container {
  width: 80vw;
  margin-top: 30px;
}

.school-details-container .main-image-container {
  width: 250px;
  height: 250px;
}

.school-image-container {
  display: flex;
}

.rows-container {
  width: calc(100% - 250px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.workshop-hint-text {
  padding-left: 10px;
  color: #00bfa5;
}

@media (min-width: 2000px) {
  .admin-container {
    padding-left: 500px;
    padding-top: 120px;
    font-size: 30px !important;
  }
  .admin-container .sidebar {
    width: 500px;
    top: 120px;
  }
  .admin-dashboard-list-text {
    font-size: 25px;
    padding: 20px;
  }

  .children-text {
    padding-left: 40px;
  }

  .admin-container .right-container .list-item-text span {
    font-size: 25px;
  }

  .dashboard-list-item {
    padding: 20px !important;
    margin: 35px 0;
  }

  .search-field {
    width: 300px;
    padding: 30px;
  }

  .right-container h3 {
    font-size: 45px;
    margin-bottom: 40px;
  }
  .class-list-container {
    max-width: 1500px;
  }
  .create-container {
    max-width: 1400px;
  }

  .main-image-container.create {
    width: 400px;
    height: 400px;
    border-radius: 200px;
    border: 3px solid #e5e5e5;
  }

  .text-input {
    height: 60px;
    padding: 15px;
  }

  .input-container {
    padding: 20px;
  }

  .register-submit-button {
    font-size: 30px;
    border-radius: 10px;
  }

  .task-item {
    font-size: 25px !important;
  }

  .register-input-container {
    margin-bottom: 60px;
  }
  .admin-container .right-container .list-item-icon span {
    font-size: 25px;
  }

  .main-image-container.crete-chapter-container {
    width: 200px !important;
    height: 200px !important;
    border: 2px solid #e5e5e5 !important;
  }
}

@media (min-width: 3000px) {
  .admin-container {
    padding-left: 700px;
    padding-top: 160px;
    font-size: 40px !important;
  }
  .admin-container .sidebar {
    width: 700px;
    top: 160px;
  }
  .admin-dashboard-list-text {
    font-size: 40px;
    padding: 25px;
  }

  .children-text {
    padding-left: 70px;
  }
  .admin-container .right-container .list-item-text span {
    font-size: 35px;
  }
  .dashboard-list-item {
    padding: 30px !important;
    margin: 35px 0;
  }

  .search-field {
    width: 500px;
    padding: 50px;
  }

  .right-container h3 {
    font-size: 60px;
    margin-bottom: 60px;
  }
  .class-list-container {
    max-width: 2000px;
  }

  .create-container {
    max-width: 2000px;
  }

  .main-image-container.create {
    width: 500px;
    height: 500px;
    border-radius: 250px;
    border: 3px solid #e5e5e5;
  }

  .text-input {
    height: 80px;
    padding: 20px;
  }

  .input-container {
    padding: 30px;
  }

  .register-submit-button {
    font-size: 40px;
    border-radius: 15px;
  }

  .register-input-container {
    margin-bottom: 80px;
  }

  .student-report-select .register-select,
  .register-submit-button {
    height: 150px;
  }

  .register-input-container-mobile {
    margin: 0;
  }
  .line-input.text-area {
    min-height: 80px;
  }

  .task-select-item {
    font-size: 55px;
    padding: 40px;
    margin-bottom: 40px;
    border: 3px solid #e5e5e5;
  }

  .poem-paragraph {
    font-size: 45px;
    line-height: 60px;
  }

  .poem-content .subtitle,
  .poem-content .subtitle p {
    font-size: 60px !important;
  }

  .poem-image-title,
  .poem-image-title p {
    font-size: 90px !important;
  }

  .task-item {
    font-size: 35px !important;
    padding: 30px !important;
    margin: 30px 0 !important;
  }

  .fields-container .task-item img {
    width: 50px !important;
    height: 50px !important;
  }

  .chapter-field.task-list-container h5,
  .chapter-field h5 {
    font-size: 30px;
  }

  .main-image-container.crete-chapter-container {
    width: 300px !important;
    height: 300px !important;
    border: 3px solid #e5e5e5 !important;
  }

  #appWrapper .content {
    font-size: 40px;
  }

  #appWrapper .itemList {
    width: 1000px;
  }

  #appWrapper .itemList .item {
    padding: 30px;
    margin: 20px 0;
  }

  .item::before {
    font-size: 40px;
  }

  .button-save {
    padding: 30px;
    width: 400px;
    font-size: 40px;
  }

  .admin-container .right-container .list-item-icon span {
    font-size: 35px;
  }

  .report-task-item:hover .option-menu {
    width: 400px;
  }

  .report-task-item {
    padding: 50px;
  }

  .report-task-item:hover .option-menu .option-menu-item {
    padding: 20px;
  }

  .editors {
    font-size: 40px;
  }

  .tagName {
    font-size: 40px;
  }
}

.chartjs-render-monitor {
  font-size: 50px;
 }
@media (min-width: 2000px) {
  .modal-button {
    font-size: 25px;
    border: 2px solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  input[type=checkbox], input[type=radio] {
    width: 20px;
    height: 20px;
  }

  .autoEvaluation-next-button span {
    position: absolute;
    top: 50%;
    left: 30%;
    z-index: 100;
    transform: translateY(-80%);
    /* transform: rotate(-5.45deg); */
    font-family: Crimson Text;
    font-style: italic;
    font-weight: bold;
    font-size: 29px;
  }
}

@media (min-width: 3000px) {
  .modal-button {
    font-size: 40px;
    border: 3px solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }

  .autoEvaluation-next-button span {
    transform: translateY(-90%)!important;
  }

  .user-information-container .user-image-container {
    width: 400px;
    height: 400px;
    border-radius: 200px;
    border: 3px solid #e5e5e5;
    overflow: hidden;
  }

  .modal-body {
    border: 3px solid #e5e5e5;
    background-color: #fff;
    border-radius: 15px;
    padding: 50px;
    width: 800px;
    margin: auto;
    flex: none;
    font-size: 35px;
  }

  .modal-title {
    font-size: 50px;
  }

  .radio-item span {
    font-size: 36px;
    line-height: 38px;
    padding-top: 20px;
  }
  input[type=checkbox], input[type=radio] {
    width: 30px;
    height: 30px;
  }
  .radio-title {
    margin-bottom: 60px;
    margin-top: 80px;
    font-size: 50px;
  }

  .autoEvaluation-background {
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.09);
    height: 2600px;
    left: 50%;
    position: relative;
    transform: rotate(-1.81deg) translateX(-50%);
    width: 1800px;
    margin-bottom: 400px;
    margin-top: 200px;
  }

  .radio-line {
    width: 1400px;
  }

  .poem-title p em, .poem-title p u {
    font-size: 70px!important;
  }

  .autoEvaluation-next-button {
    position: relative;
    width: 260px;
    left: 65%;
    top: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .autoEvaluation-next-button span {
    font-size: 50px;
    position: absolute;
    z-index: 100;
    transform: translateY(-30%);
    font-family: Crimson Text;
    font-style: italic;
    font-weight: bold;
    color: white;
  }


  .autoEvaluation-next-button img {
    width: 100%;
    height: 100%;
  }

  .autoEvaluation-title {
    font-size: 55px;
    line-height: 66px;
  }

  .autoEvaluation-modal-container {
    position: fixed;
    width: 1360px;
    height: 1000px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 40px;
    z-index: 100;
  }

  .autoEvaluation-modal-container .title {
    font-size: 65px!important;
    height: 120px;
  }
  .autoEvaluation-modal-container .subtitle {
    height: 60px;
    font-size: 35px;
    line-height: 150%;
    margin-top: 30px;
  }

  .modal-next-button {
    width: 240px;
    height: 80px;
    border-radius: 40px;
  }

  .chart {
    width: 400px!important;
    height: 400px!important;
  }

  .modal-next-button span {
    font-size: 40px;
  }

  .poem-content {
    width: 1800px;
  }
  .last-page.last-page-container {
    width: 1000px;
  }

  .student-container {
    height: unset;
  }

  .edit-container {
    max-width: 1600px;
  }

  .register-select {
    height: 140px;
  }

  .login-paragraph , .register-paragraph {
    font-size: 45px;
  }

  .register-paragraph  {
    margin-bottom: 50px;
  }

  .login-title, .register-title {
    font-size: 120px;
  }

  .login-text-input {
    height: 120px;
    font-size: 40px;
    padding: 40px;
  }

  .login-input-container {
    margin-bottom: 60px;
  }

  .error-span {
    font-size: 30px;
    bottom: -40px;
  }
  .login-forgot-password , .login-first-time , .register-first-time.terms-and-conditions , .register-first-time {
    font-size: 40px;
  }
}
