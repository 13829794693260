.centered {
    position: relative;
    width: 100vw;
    left: 10%;
    transform: translateX(-10%);
    text-align: center;
    top: 40px;
}

.chapters-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* flex-wrap: wrap; */
  margin-top: 75px;
  align-items: center;
  height: 100%;
}

.container>chapter-container {
  /* flex: 0 50%; */
  /*demo*/
  box-sizing:border-box;
}

/* .poem-image-container .poem-image-content .poem-border { */
.poem-border {
    width: 230px;
    position: absolute;
    right: -23px;
    bottom: 0;
  }

.chapter-container {
    position: relative;  
}

@media (max-width: 769px) {

  .chapter-container {
    margin: 20px 35px;
  }
}

.chapter-container:hover {
  cursor: pointer;
}

  .chapter-circle {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #204E6E;
  }

  .chapter-incomplete {
    background: #CCCCCC;
  }

  .chapter-inner-circle {
    position: relative;
    width: 130px;
    height: 130px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    
    border: 6px solid #FFFFFF;
    box-sizing: border-box;
  }

  .chapter-inner-circle-text {
    position: relative;
    width: 33px;
    height: 76px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 65px;
    line-height: 76px;
    text-align: center;
    
    color: #FFFFFF; 
  }

  .chapter-text {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 97px;
    height: 35px;
    /* left: 905px; */
    /* top: 560px; */
    
    font-family: Crimson Text;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 35px;
    /* identical to box height */
    
    text-align: center;
    
    color: #000000;
  }
  
  .chapter-incomplete {
    background: #CCCCCC;
  }

  .icon-outer {
    position: absolute;
    width: 48px;
    height: 48px;
    right: 0px;
    bottom: 0px;
    border-radius: 50%;
    /* background: #6CD998; */
  }

  .icon-inner {
    background: #FFFFFF;
    width: 48px;
    height: 48px;
    border: 5px solid white;
    border-radius: 50%;
  }

  .icon-inner-incomplete {
    background: #E2252B;
    width: 48px;
    height: 48px;
    border: 5px solid white;
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .icon-inner-incomplete>img {
    position: absolute;
    left: 20px;
    top: 15px;
  }

  .bg-grid{
    background-image: url("../../utils/images/BG.png");
    width: 100%;
    height: 100%;
  }

  .chapter-row {
    width: 100vw;
    display: flex;
    justify-content: center;
  }