
.container {
  padding: 30px;
  display: flex;
}
.left-container {
  width: 30%;
}

.right-container {
  width: 70%;
  display: flex;
  padding: 10%;
  flex-direction: column;
}

.api-button {
  padding: 15px;
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  cursor: pointer;
  margin: 10px 0;
}

.api-button:hover {
  opacity: 0.5;
}