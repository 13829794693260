.last-page.last-page-container {
  width: 600px;
  margin: auto;
  margin-top: 150px;
}

.last-page.last-page-container-mobile {
  width: 100%;
}

.last-page-content {
  display: flex; 
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  margin-top: 100px;
}

.well-done-image {
  z-index: 10;
}

.last-page-content .bubble-image {
  width: 210px;
}

.last-page .poem-background{
  z-index: 0;
}

.last-page-content .next-button {
  width: 180px;
  font-size: 25px;
  height: 60px;
  font-style: italic;
  font-family: "Crimson Text";
}

.back-button-container {
  position: relative;
}

.back-button-container .back-link {
  position: absolute;
  top: 0%;
  left: 0%;
  font-size: 22px;
  z-index: 1000;
  color: #fff;
  text-transform: none;
  text-decoration: none;

  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 25px;
}