/* *{
	border: 0;
	margin: 0;
	padding: 0;
}
body{
	margin: 0;
	padding: 0;
	position: absolute;
	height: 100%;
	min-height: 100%;
	min-width: 100%;
} */
#appWrapper{
	font-family: sans-serif;
	font-weight: 300;
	padding: 3rem;
	margin: 0;
	box-sizing: border-box;
	position: relative;
	line-height: 1.5;
	font-size: .9rem;
	box-sizing: border-box;
	height: 100%;
	background: transparent;
	left: 50%;
	transform: translateX(-50%);
}
.lists{
	border: 1px solid #e5e5e5;
	display: flex;
	margin: 0 5vw;
    padding: 0;
	/* width: 90vw; */
	}
.itemList{
	font-family: sans-serif;
	font-weight: 300;
	min-width: 300px;
	display: flex;
	flex-direction: column;
	counter-reset: list;
	z-index: 1;
	}
.item{
	list-style: none;
	margin: .4rem 0;
	color: #555;
	background: #fff;
	border-radius: 3px;
	overflow: hidden;
	position: relative;
	-webkit-user-select: none;
	user-select: none;
	display: flex;
	cursor: -webkit-grab;
	cursor: grab;
	box-shadow: 1px 2px 4px rgba(0,0,0,.3);
	transition-timing-function: ease;
	transition-duration: .4s;
	transition-property: transform opacity;
	width: 90%;
	}
	.content{
		padding: 1rem 2rem 1rem 1rem;
    }
	.item .touched{
		opacity: .5 !important;
		cursor: -webkit-grabbing;
		cursor: grabbing;
		z-index: -1;
	}
	.item:before{ 
		content: counter(list);
		position: relative;
		padding: 0 1rem;
		top: 0;
		color: #bbb;
		font-weight: 700;
		font-family: 'Lucida Sans Console', sans-serif;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		counter-increment: list;
        background: #f5f5f6;
	}
	
	.button-save {
		width: 100px;
		/* border: 2px solid #555; */
		border-color:#555 !important;
		/* left: 50%; */
		/* transform: translateX(-50%); */
		position: relative;
	}

	.center {
		left: 50vw !important;
		transform: translateX(-50%) !important;
	}