.roullete-slot-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.roullete-slot-text {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 14px;

  line-height: 18px;
  text-transform: uppercase;
}

/* ----------- */

.circle-container {
  position: relative;
  /* 1 */
  width: 16em;
  height: 16em;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  /* 2 */
  box-sizing: content-box;
  /* 3 */
  margin: 5em auto 0;
  border: 2px solid #e1e1e1;
}

.inner-circle {
  position: absolute;
  /* 1 */
  width: 13em;
  height: 13em;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  /* 2 */
  box-sizing: content-box;
  /* 3 */
  margin: 4.3em auto 0;
  border: 2px solid #e1e1e1;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -63%);
}

.circle-container > * {
  /* 4 */
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6em;
  height: 6em;
  margin: -3em;
}

.bg-white {
  background-color: white;
}

/* Self Management */
.circle-container > :nth-of-type(1) {
  transform: rotate(-20deg) translate(7em) rotate(20deg);
}
/* Responsable Decision making */
.circle-container > :nth-of-type(2) {
  transform: rotate(50deg) translate(6em) rotate(-50deg);
}
/* Relationship Skills */
.circle-container > :nth-of-type(3) {
  transform: rotate(132deg) translate(6em) rotate(-132deg);
}
/* Social Awareness */
.circle-container > :nth-of-type(4) {
  transform: rotate(203deg) translate(6em) rotate(-203deg);
}
/* Self Awareness */
.circle-container > :nth-of-type(5) {
  transform: rotate(275deg) translate(6em) rotate(-275deg);
}

.circle-container img {
  display: block;
  width: 100%;
  border-radius: 50%;
  filter: grayscale(100%);
}
.circle-container img:hover {
  filter: grayscale(0);
}

.start-button {
  background: #e2252b;
  border-radius: 31.5452px;
  width: 120px;
  height: 48px;

  font-family: Crimson Text;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  border: none;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(0%, 50%) !important;
}

.start-button:focus {
  outline: none;
}

.roulette-container {
  position: absolute;
  /* left: 50%; */
  top: 50%;
  transform: translate(-25%, -60%);
  z-index: 99999 !important;
}

@media (min-width: 2000px) {
  .inner-circle {
    width: 26em;
    height: 26em;
    margin: 9.6em auto 0;

  }
  .circle-container {
    width: 32em;
    height: 32em;
    margin: 13em 7em 0;
  }
  .circle-container > * {
    width: 12em;
    height: 12em;
  }

  .roullete-slot-container {
    width: 200px;
    height: 200px;
  }
  .roulette-container {
    transform: translate(-34%, -65%);
  }
  .roullete-slot-text {
    font-size: 30px;
    line-height: 35px;
  }
  /* Self Management */
  .circle-container > :nth-of-type(1) {
    transform: rotate(-20deg) translate(13em) rotate(20deg);
    margin-left: -85px;
    margin-top: -70px;
  }
  /* Responsable Decision making */
  .circle-container > :nth-of-type(2) {
    transform: rotate(50deg) translate(11em) rotate(-50deg);
    margin-left: -105px;
    margin-top: -44px;
  }
  /* Relationship Skills */
  .circle-container > :nth-of-type(3) {
    transform: rotate(132deg) translate(12em) rotate(-132deg);
    margin-left: -65px;
  }
  /* Social Awareness */
  .circle-container > :nth-of-type(4) {
    transform: rotate(203deg) translate(14em) rotate(-203deg);
    margin-left: -90px;
    margin-top: -60px;
  }
  /* Self Awareness */
  .circle-container > :nth-of-type(5) {
    transform: rotate(275deg) translate(14em) rotate(-275deg);
    margin-left: -115px;
  }
}



@media (min-width: 3000px) {
  .inner-circle {
    width: 39em;
    height: 39em;
    margin: 14.1em auto 0;

  }
  .circle-container {
    width: 48em;
    height: 48em;
    margin: 18em 14em 0;
  }
  .circle-container > * {
    width: 18em;
    height: 18em;
  }

  .roullete-slot-container {
    width: 300px;
    height: 300px;
  }
  .roulette-container {
    transform: translate(-34%, -65%);
  }
  .roullete-slot-text {
    font-size: 30px;
    line-height: 35px;
  }
  /* Self Management */
  .circle-container > :nth-of-type(1) {
    transform: rotate(-20deg) translate(17.5em) rotate(20deg);
    margin-top: -105px;
    margin-left: -90px;
  }
  /* Responsable Decision making */
  .circle-container > :nth-of-type(2) {
    transform: rotate(50deg) translate(16.5em) rotate(-50deg);
    margin-left: -160px;
    margin-top: -50px;
  }
  /* Relationship Skills */
  .circle-container > :nth-of-type(3) {
    transform: rotate(132deg) translate(17em) rotate(-132deg);
    margin-left: -120px;
    margin-top: -50px;
  }
  /* Social Awareness */
  .circle-container > :nth-of-type(4) {
    transform: rotate(203deg) translate(24em) rotate(-203deg);
    margin-left: -95px;
    margin-top: -55px;
  }
  /* Self Awareness */
  .circle-container > :nth-of-type(5) {
    transform: rotate(275deg) translate(21em) rotate(-275deg);
    margin-left: -190px;
    margin-top: -90px;
  }
}
