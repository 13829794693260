.settings-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}
.settings-form {
  justify-content: center;
  align-items: center;
}

.settings-paragraph {
  text-align: center;
}

.settings-title {
  text-align: center;
}

.w-35 {
  width: 35%!important;
}

.profile-image-container {
  height: 150px;
  width: 150px;
  border-radius: 75px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  margin-bottom: 40px;
  position: relative;
}

.profile-image-container input {
  display: none;
}

.profile-image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.upload-button {
  padding: 5px 10px;
  background-color: #E2252B;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  width: 70px;
  left: calc(50% - 35px);
}

.upload-button:hover {
  color: #e5e5e5;
}

.login-form.settings {
  height: auto;
}

@media (min-width: 2000px) {
  .login-title.settings-title {
    font-size: 6rem;
  }
  .settings-paragraph{
    font-size: 2.5rem;

  }
  .profile-image-container {
    height: 250px;
    width: 250px;
    border-radius: 125px;
  }
  .upload-button {
    padding: 10px 20px;
    border-radius: 15px;
    width: 150px;
    left: calc(50% - 75px);
    font-size: 30px;
  }
  .register-text-input {
    height: 100px;
    padding: 25px;
    border-radius: 10px;
    font-size: 1.75rem;
  }
  .login-submit-button {
    height: 100px;
    padding: 25px;
    border-radius: 20px;
    font-size: 2rem;
  }
  .register-input-container {
    margin: 4px;
    margin-bottom: 20px;
  }
}

@media (min-width: 3000px) {
  .login-title.settings-title {
    font-size: 8rem;
  }
  .settings-paragraph{
    font-size: 3rem;

  }
  .profile-image-container {
    height: 400px;
    width: 400px;
    border-radius: 200px;
  }
  .upload-button {
    padding: 15px 30px;
    border-radius: 20px;
    width: 200px;
    left: calc(50% - 100px);
    font-size: 40px;
  }
  .register-text-input {
    height: 150px;
    padding: 35px;
    border-radius: 20px;
    font-size: 2.5rem;
  }
  .login-submit-button {
    height: 150px;
    padding: 35px;
    border-radius: 20px;
    font-size: 3rem;
  }
  .register-input-container {
    margin: 6px;
    margin-bottom: 30px;
  }
}
