.editorContainer {
  margin-top: 40px;
}

.editors {
  border: 1px #E51B1C solid;
  padding: 1em;
  margin: 1em;
  font-family: 'Open Sans';
  font-size: 90%;
}

.test-tags {
  list-style: none;
  flex-direction: row;
}

hr {
  margin-bottom: 20px;
  width: 100%;
}

.tagName {
  text-transform: capitalize;
  position: relative;
  font-size: 20px;
  top: 0px;
  left: 0px;
}

.button-create-test {
  margin: 5px;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 5px;
  width: auto;
  border: 1px solid #e5e5e5;
}

.button-clear {
  border: 1px solid #E51B1C;
}

.button-clear:hover {
  background-color: #c54141
}

.button-create-test:focus {
  outline:0;
}

.button-remove-tag {
  border-radius: 50%;
  float: right;
  height: 25px;
  padding: 0 5px;
  width: 25px;
}

.select-new-tag {
  float: right;
  margin: 0 5px;
}

.select-input-tag {
  margin: 0 10px;
}

.placeholder-container {
  display: flex;
  margin-top: 10px;
}

.placeholder-container-label {
  align-self: flex-start;
  margin-right: 5px;
  width: 40%;
}

.placeholder-container-placeholder {
  align-self: flex-end;
  margin-left: 5px;
  width: 60%;
}

.container-buttonsContainer {
  background: white;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;

  top: -20px;
  background: white;
}

.buttonsContainer .close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.buttonsContainer .close-button:hover {
  opacity: .6;
}

.buttonsHalfContainer {
  margin: 0 10px;
}

.left {
  float: left;
  display: inline-block;
}

.titleError {
  float: center;
  display: inline-block;
  position: center;
  color: #E51B1C;
}

.right {
  float: right;
  display: inline-block;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1020;
  vertical-align:bottom;
}

.create-task-input {
  width: 200px;
  margin: 0 10px;
}

