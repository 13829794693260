.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .modal-main-mobile {
    position:fixed;
    width: 400px;
    height: 80%;
    left:50%;
    transform: translate(-50%, 0%);
  }
  
  .display-block {
    display: table-cell !important;
  }
  
  .display-none {
    display: none !important;
  }