.text-input {
    height: 40px;
    border-radius: 5px;
    width: "100%";
    border: 1px solid #e5e5e5;
    padding-left: 5px;
}

.input-container {
    display: flex;
    width: 200px;
    margin: 10px;
    flex-direction: column;
}

.select-container {
    width: 400px;
}

.form-container {
    width: 500px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.submit-button {
    width: 100%;
    height: 40px;
    color: #fff;
    background-color: cadetblue;
}